<template> 
<div>
            <v-row dense class="caption px-2 item font-weight-black"   >
              <v-col class="text-center">
              Tipo de Cabaña: {{tipoHabitacion}} {{tipoHabitacion2}}
              </v-col> 
            </v-row>
            
            <v-row dense>
              <v-col >
                 <v-list dense subheader v-for="(item, j) in ambientesObtenidos"
                    :key="`${j}-${item.det_id_amb}`" :id="item.det_id_amb">
                    <v-subheader 
                      class="text-uppercase body-2 font-weight-black font-italic item" >
                      <v-icon dense :color=colorMenu class="ma-0 pa-0">mdi-menu-right</v-icon>
                      {{ item.det_descripcion }}
                      <v-btn class="mx-2"  x-small outlined color="blue" @click="editarCategoria(item)" >                
                                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                        <v-btn class="mx-0"  outlined x-small color="red" @click="borrarCategoria(item)">                
                                         <v-icon small>mdi-delete</v-icon>
                                  </v-btn>   
                    </v-subheader> 
                        <v-list-item
                            dense
                            v-for="(subitem, k) in filtroPorTipo(item.det_id_amb)"
                            :key="`${k}-${item.det_id_amb}`"
                            class="itempar alto_item" style="font-size:11px !important"
                          >    
                            <v-list-item-content  >                            
                                      <v-list-item-title
                                        class="caption" style="font-size:11px !important" >
                                          <v-btn  icon  x-small color="blue" @click="editar(item)" >                
                                                      <v-icon  x-small>mdi-pencil</v-icon>
                                              </v-btn>
                                          <v-btn icon x-small color="red" @click="borrar(item)">                
                                                      <v-icon x-small>mdi-delete</v-icon>
                                              </v-btn>  
                                            {{subitem.inv_cantidad}} {{subitem.art_descripcion}}
                                            
                                      </v-list-item-title>
                            </v-list-item-content>                                                                          
                    </v-list-item>
                     <v-btn color="blue" class="my-2" dense small block outlined  @click="nuevoItem(item)">AGREGAR EN {{ item.det_descripcion}} </v-btn>
                          
              </v-list>
              </v-col>            
            </v-row> 
            <v-dialog v-model=dialog v-if=dialog persistent width="600px">                 
                                <v-card>
                                        <v-card-title>
                                                <span v-if="editItem.id">Modificar: {{editItem.item}}</span>
                                                <span v-else>Agregar</span>
                                        </v-card-title>
                                        <v-card-text dense>
                                                  <v-row>
                                                        <v-col cols="10" sm="10"  class="ma-0 pa-2">
                                                        <v-select :rules="[v => !!v || 'Requerido']"  autofocus class="caption" persistent-placeholder dense 
                                                                                        v-model="editItem.articuloseleccionado" item-text='art_descripcion' item-value='inv_id_articulo'  :items="articulosfiltrados" label="Articulo" return-object ></v-select> 
                                                        </v-col>            
                                                        <v-col cols="2" sm="2"  class="ma-0 pa-2">
                                                              <v-text-field type="number" v-model="editItem.cantidad" label="Cant." dense outlined  placeholder=" "></v-text-field>
                                                        </v-col> 
                                                                                       
                                                    </v-row>                                      
                                        </v-card-text>
                                        <v-card-actions >                                               
                                                <v-spacer></v-spacer>
                                                <v-btn color="green" class="mx-2 pa-4" dark dense @click="close()">Cancelar</v-btn>                                                
                                                <v-btn color="green" class="mx-2 pa-4" dark dense  @click="grabarItem(editItem)">Guardar Cambio</v-btn>
                                        </v-card-actions>
                                </v-card>
    </v-dialog>             
          </div>                  
</template>

<script>
import axios from 'axios'
export default {
  name: 'listarItemsInventarioAdmin',
  props: {
        idcomplejo:Number,
        tipoHabitacion: String,
        tipoHabitacion2: String,
        pax: Number,
        superficie: Number,
        plano: String,
        inventarioObtenido: Array,
        intentarioObtenidoFiltrado: Array,
        ambientesObtenidos: Array,
        inventarioCompleto: Array,
        itemTabs: Array,
        colorMenu: String,
        moneda: String,
        piegeneral: String
  },
  data() {
           return { 
            dialog:false,
            editItem: {},
            articulosfiltrados:{},
            articuloElegido:'',
            articuloseleccionado:[]

                      
             //  path_plano: require('../../assets/rda/'+ this.plano + '.png'),
             //  path_sup: require('../../assets/rda/superficie2.png'),
             //  path_maximo: require('../../assets/rda/personas2.png')                                 
           }
  },
  methods: {
    //articuloseleccionados(val){
                         //     alert(JSON.stringify(val))
      //                      },
    filtroPorTipo(id) {
                                    //  alert(JSON.stringify(this.inventarioObtenido)) 
                                      let resultado = [];
                                    resultado = this.inventarioObtenido.filter(t => t.cab_id_ambiente === id)
                             
                                 //   alert(JSON.stringify(resultado))
                                    return resultado;
                                  },
     nuevoItem(campo){
                        
                  //  alert(JSON.stringify(campo))
                        this.editItem={}
                       
                        this.editItem.cantidad=1
                        this.editItem.id=''
                       
                        this.editItem.inv_id_amb=campo.id
                        this.editItem.cab_id_ambiente=campo.det_id_amb
                        this.editItem.amb_id_tipo_cabana=campo.amb_id_tipo_cabana
                        



                        this.editItem.estado=1
                        this.editItem.inventarioObtenido=this.inventarioObtenido
                        this.editItem.idcomplejo=this.idcomplejo  
                        this.consulta_articulos(this.editItem)   
                         this.dialog=true 
                      //   alert(JSON.stringify(this.editItem))
    },
    async consulta_articulos(campo){
                    //  alert(JSON.stringify(this.inventarioObtenido))
                     //  alert(campo.inv_id_amb)
                       this.articulosfiltrados=this.inventarioCompleto.filter(
                                      inventario => inventario.cab_id_ambiente === campo.cab_id_ambiente
      )
          //            this.articulosfiltrados=this.ordenar(this.articulosfiltrados)
     // alert(JSON.stringify(this.inventariofiltrado))
                        // alert(campo.idcomplejo + ' - ' + campo.idambiente)
       //                  await this.$http.get(process.env.VUE_APP_API +'/inventario/articulos',
         //                {params:{
           //                       idcomplejo: campo.idcomplejo,
             //                     idambiente: campo.idambiente
           //         }
       // })
         //                 .then((response) => {
           //                                   this.articulos= response.data.data                                                                                 
             //             })
               //          .catch(function(error){
                 //                alert(error)                                 
                  //       })
          },

    grabarItem(campo){
              let resultado=''
              let valores={};
         //   alert(JSON.stringify(campo.inv_id_amb))
              valores.inv_id_articulo=campo.articuloseleccionado.inv_id_articulo
              valores.art_descripcion=campo.articuloseleccionado.art_descripcion
              valores.inv_cantidad=campo.cantidad
              valores.inv_id_amb= campo.inv_id_amb
              valores.estado=campo.estado

              valores.cab_id_ambiente=campo.cab_id_ambiente
              valores.cab_id_tipo_cabana=campo.amb_id_tipo_cabana

                                                        //      IdTipoCabana: campo.amb_id_tipo_cabana,
                                          //      IdTipoAmbiente: campo.cab_id_ambiente

        //      alert(JSON.stringify(valores.inv_id_amb))
        //      valores.inv_id_articulo=campo.articuloseleccionado.
          //    alert(campo.articuloseleccionado.art_descripcion)
          //    alert(valores.art_descripcion)
          //  alert(JSON.stringify(valores))
              
              if (!campo.id){
                        //alert('sad')
                        axios.post(process.env.VUE_APP_API +'/inventario/stock',  {
                                        params: {                                                 
                                                IdArticulo: valores.inv_id_articulo,
                                                IdAmbiente:  valores.inv_id_amb,
                                          //      IdTipoCabana: campo.amb_id_tipo_cabana,
                                          //      IdTipoAmbiente: campo.cab_id_ambiente,
                                                Cantidad: valores.inv_cantidad,
                                                Estado: valores.estado                                                                                                                             
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){
                                              //campo.id=response.data.respuesta
                                              valores.inv_id=response.data.respuesta
                                              
                                        //  alert(JSON.stringify(valores))
                                               
                                              this.inventarioObtenido.push(valores)
                                             
                                               
                                           //   this.intentarioObtenidoFiltrado.push(valores) 
                                             //   this.cartaObtenidaFiltrada.push(item)
                                                this.dialog=false                                              
                                            }
                                            else {
                                              alert("Error en Agregar item")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert("Error" + err)
                                          }) 
                                          // alert(resultado)
                                          return resultado
              }else{ //SI ES ACTUALIZAR
                      axios.put(process.env.VUE_APP_API +'/inventario/stock/',  {
                                        params: {   
                                                id: campo.id,                                              
                                                IdCategoria: campo.idcategoria,
                                                Item: campo.item,
                                                IdComplejo: campo.idcomplejo,
                                                Estado: campo.estado,
                                                Precio: campo.precio,
                                                PrecioSocio: campo.preciosocio,
                                                Descripcion: campo.descripcion,
                                                Descripcion2: campo.descripcion2,
                                                Descripcion3: campo.descripcion3                                                                                 
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){  
                                                let indice= this.cartaObtenidaFiltrada.findIndex(contenido=> contenido.id == response.data.respuesta) 
                                                Object.assign(this.cartaObtenidaFiltrada[indice], campo)                                               
                                                this.dialog=false                                              
                                            }
                                            else {
                                              alert("Error en Actualizar item")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert(err)
                                          }) 
                       this.dialog=false                      
              }        
    },
    

     
     close(){
            this.articuloElegido='' 
            this.dialogCategoria=false
            this.dialog=false
    },  
        
  },
  mounted: function() {
                  //  alert(this.inventarioObtenido)
                   

  },
  created: async function() {
}
  
}
</script>
<style scoped>
.itempar:nth-child(odd) {
  background-color: #f7f7f7;
}

.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 0;
}

.alto_item {
      min-height: 20px;
      height: 20px;   
}

.item {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  border-color: green;
  border-width: 1px;
  height: auto;
  min-height:15px;
  color: green !important;
}
</style>
